@import "../../../assets/scss/definitions/mixins";

// header

.wallet-connect {
  .index-part {
    z-index: 999;
  }
}

.copy-icon-container {
  border: none;
  cursor: pointer;
  background: none;

  img {
    width: 15px;
    height: 20px;
  }
}

.header-parent-section {
  width: 100%;
  background: linear-gradient(108.79deg, #4c248a 0%, #7b4dcf 100%);

  .page-content {
    @include pageContent(1067px);
  }

  .rowHeader {
    display: flex;
    justify-content: space-between;
    padding: 20px 15px 15px 15px;
    align-items: center;
  }

  .logo-sweet-mobile {
    display: none;
  }

  .burger {
    display: none;
  }

  .columns {
    padding: 0 !important;
  }
}

.coins-block {
  .presale-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include textTitle(600, 14px, unset, #280d5f);
    margin-top: 10px;
    font-family: Montserrat;

    .presale-claim {
      padding: 5px 10px;
      border: 1px solid green;
      @include textTitle(600, 9px, unset, #fff);
      font-family: Montserrat;
      border-radius: 20px;
      //background: linear-gradient(180deg, #03a66d 0%, #03a66d 100%);
      cursor: pointer;
    }

    .presale-div {
      display: flex;
      justify-content: space-between;

      p {
        margin-bottom: 7px;
      }
    }
  }
}

// .staked {
//   .first-count {
//     background-color: #00ab6b !important;
//     display: none !important;
//   }
// }

// .staked {
//   .first-count {
//     &:first-child {
//       display: flex !important;
//     }
//   }
// }
// .staked > .first-count:not(:nth-child(2)) {
//   display: none;
// }
.first-count {
  display: flex;
}
.card-close-btn {
  position: absolute !important;
  right: 10px;
  top: 0px;

  z-index: 10;
  svg {
    fill: white;
  }
}

// nav
.nav-and-connect {
  @include flexMixin(center, space-between);
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-gap: 22px;
  grid-auto-flow: column;

  .nav-container {
    .info-content {
      display: grid;
      grid-gap: 22px;
      grid-auto-flow: column;
    }
  }
  .info-item-h2 {
    margin: 0;
    @include textTitle(400, 20px, inherit, #fff);
    cursor: pointer;
    text-decoration: none;
  }

  .connect-btn-wallet {
    @include buttonMixin(linear-gradient(180deg, #00ab6b 0%, #076c47 100%));
    @include textTitle(400, 16px, 16px, #ffffff);
    font-family: Arciform Sans;
    text-transform: capitalize;
    padding: 12px;

    &:active {
      @include btnAnimate();
    }
  }
}

.content-api {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.buy-sweet-btn-href {
  text-decoration: none;
}
.card-pinksale {
  .prev {
    background-size: 100% 100%;
    box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 10px 30px;
    color: white;
  }
  outline: none;
  background-image: url("../../../svg/bg-card.png") !important;
  background-size: 100% 100%;

  // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 30px !important;
  border: none !important;
  max-width: 600px !important;
  width: 100% !important;
  border: none !important;
  .image-card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
    img {
      width: 225px;
    }
  }
  .text-card-section {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: start;
    grid-gap: 90px;
    gap: 90px;

    img {
      width: 120px;
      top: 50px;
      position: relative;
    }
    .participate-sweet {
      font-family: "Arciform Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 139.4%;
      letter-spacing: 0.04em;
      color: #ffffff;
    }
    .time {
      font-family: "Arciform Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 139.4%;
      /* or 50px */

      letter-spacing: 0.04em;

      color: #632b94;
    }
    .text-desc {
      display: flex;
      flex-direction: column;
      align-items: start;
      grid-gap: 20px;
    }
  }
  .buy-href {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.buy-sweet-btn-href {
  background: linear-gradient(180deg, #ea7acb 0%, #c76aca 100%);
  box-shadow: 0px 2px 0px #804698;
  border-radius: 20px;
  button {
    padding: 10px 30px;
    color: white;
  }
}
// about
.page-content {
  .content-container-about {
    margin-left: 0;
    width: 100%;
    padding: 0px 15px;
    // .columns {
    .stack-mobile {
      display: none;
    }

    // }

    @include flexMixin(start, space-between);
    flex-wrap: wrap;

    .about-header {
      @include textTitle(400, 40px, 1.5, #ffffff);
    }

    .about-text {
      @include textTitle(400, 20px, 30px, #ffffff);
      margin-top: 20px;
      font-family: Montserrat;
    }

    .connected-wallet-p {
      display: none;
    }

    .stack-values {
      @include wrapFlex();
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 150px));
      // max-width: 345px;
      width: 100%;
      background: #effaff;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
      padding: 22px 18px;
      grid-row-gap: 40px;

      div {
        @include flexMixin(start, start);
        flex-direction: column;

        span {
          font-family: "Arciform Sans";
          @include textTitle(400, 14px, 14px, #280d5f);
          white-space: nowrap;
        }

        strong {
          @include textTitle(600, 16px, 20px, #7645d9);
          font-family: Montserrat;
        }

        &:nth-child(3),
        &:nth-child(4) {
          //margin-top: 40px;
        }
      }

      .stake-unique {
        justify-self: flex-end;
      }
    }

    .buy-sweet-open {
      margin-top: 30px;
      @include flexMixin(center, start);

      grid-gap: 17px;

      a {
        text-decoration: none;
      }

      .button-ido {
        @include buttonMixin(linear-gradient(180deg, #1fc7d4 0%, #24a4ae 100%));
        @include textTitle(400, 16px, 16px, #ffffff);
        padding: 12px 25px;
        text-transform: none;

        &:active {
          @include btnAnimate();
        }
      }
    }

    .buy-sweet-btn {
      cursor: pointer;
      @include buttonMixin(linear-gradient(180deg, #ea7acb 0%, #c76aca 100%));
      @include textTitle(400, 20px, 16px, #ffffff);
      padding: 12px 45px;
      text-transform: unset;

      &:active {
        @include btnAnimate();
      }
    }

    .social-icons-header {
      margin-top: 30px;
      @include flexMixin(center, start);
      display: grid;
      grid-auto-flow: column;
      grid-gap: 4px;

      .show-hover {
        display: none;
      }

      a {
        &:nth-child(1) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(2) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(3) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(4) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(5) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(6) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(7) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(8) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(9) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }
        &:nth-child(10) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }
      }
    }

    .social-icons-header-mobile {
      margin-top: 50px;
      @include flexMixin(center, center);
      display: grid;
      grid-auto-flow: column;
      grid-gap: 5px;

      .show-hover {
        display: none;
      }

      a {
        &:nth-child(1) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(2) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(3) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(4) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(5) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(6) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(7) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(8) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(9) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }

        &:nth-child(10) {
          &:hover {
            .show-hover {
              display: inline-block;
            }

            .hide-hover {
              display: none;
            }
          }
        }
      }
    }

    .icons-and-animate-box {
      @include flexMixin(start, space-between);
    }

    .swt-coins {
      position: relative;
      // width: 350px;
      height: 350px;
      bottom: 0;

      .swt1 {
        top: 0;
        width: 330px;
        animation: floats 6s ease-in-out 0.33s infinite running
          alternate-reverse;

        position: absolute;
        z-index: 3;
      }

      .swt2 {
        animation: floatL 6s ease-in-out 0.33s infinite running
          alternate-reverse;

        position: absolute;
        width: 255px;
        z-index: 2;
        left: 0;
        top: 100px;
        left: -100px;
      }

      .swt3 {
        // animation: float 6s ease-in-out infinite;
        animation: float 4s ease-in-out 0.33s infinite running alternate-reverse;
        position: absolute;
        width: 210px;
        z-index: 1;
        // bottom: 0;
        left: 85px;
        top: 220px;
      }
    }
  }
}

// my account
.presale-claim-inactive {
  box-shadow: 5px 0px 5px 0px #0000001a;
  padding: 5px 10px;
  border: none;
  @include textTitle(600, 9px, unset, #280d5f66);
  font-family: Montserrat;
  border-radius: 20px;
  // cursor: pointer;
  background-color: #f0f0f0;

  &:disabled {
    background: #f0f0f0;
    cursor: context-menu;
    pointer-events: none;
  }
}

.staked-swt {
  .presale-claim-inactive {
    box-shadow: 5px 0px 5px 0px #0000001a;
    padding: 5px 10px;
    border: none;
    @include textTitle(600, 9px, unset, #280d5f66);
    font-family: Montserrat;
    border-radius: 20px;
    // cursor: pointer;
    background-color: #f0f0f0;

    &:disabled {
      background: #f0f0f0;
      cursor: context-menu;
      pointer-events: none;
    }
  }
}

.staked-swt {
  .presale-claim {
    padding: 5px 10px;
    border: 1px solid #d4d5d9;
    @include textTitle(600, 9px, unset, #fff);
    font-family: Montserrat;
    border-radius: 20px;
    //background: linear-gradient(180deg, #03a66d 0%, #03a66d 100%);
    cursor: pointer;
    background-color: #00ab6b;
  }
}

.staked {
  .staked-days {
    text-align: right;
    width: 70%;
  }

  // .staked-swt:nth-child(4) {
  //   span:nth-child(2) {
  //     padding-left: 17px;
  //   }
  // }
}

.frezes-count {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  flex-direction: column;

  span {
    display: flex;
    align-items: flex-end;

    gap: 10px;

    span {
      @include textTitle(600, 16px, 24px, #280d5f);
      font-family: Montserrat;
    }
  }

  span {
    @include textTitle(600, 20px, 34px, #280d5f);
    font-family: Montserrat;
  }
}

.staked-days {
  p {
    @include textTitle(600, 16px, 34px, #280d5f);
    font-family: Montserrat;
  }
}

.frezes-staked {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.staked-swt {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;

  span {
    @include textTitle(600, 16px, unset, #280d5f);
    font-family: Montserrat;

    &:first-child {
      width: 63px;
    }

    &:nth-child(3) {
      margin-left: 15px;
    }
  }
}

// .staked {
//   .first:not(:first-child) {
//     display: none;
//   }
//   .first {
//     display: block;
//   }
// }
// div ul:not(:first-child) {
//   background-color: #900;
// }

.api-logo {
  .icon-button {
    display: none;
  }
  .show-nav-panel {
    border-radius: 0 0 20px 20px;
    transition: all 0.5s;
    z-index: 0;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    a {
      font-size: 28px;
      color: #639;
    }
  }
}

.account-div {
  .my-account {
    padding: 14px;
    @include buttonMixin(linear-gradient(180deg, #1fc7d4 0%, #24a4ae 100%));
    @include textTitle(400, 16px, 16px, white);

    &:active {
      @include btnAnimate();
    }
  }
}

.box-close {
  display: flex;
  justify-content: space-between;
  padding: 0 13px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 21px;
    height: 21px;
    background: #afa3a34d;
    border-radius: 6px;
    cursor: pointer;

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.popup-soon {
  position: relative;
  z-index: 9999;
  overflow: hidden;
  top: 8px !important;
  border-radius: 20px;

  div {
    justify-content: center;
    @include textTitle(400, 20px, 20px, #280d5f);
    background-color: rgba(212, 213, 217, 1) !important;
  }
}

.drawer-full-content {
  .drawwer-box {
    max-width: 400px;
    width: 100%;
    padding: 20px 0 20px 10px;
    top: 0;
    background-color: #effaff;

    .coins-block {
      .coins {
        display: flex;
        flex-direction: column;
        margin: 0 0 20px 0;
        border-bottom: 1px solid #280d5f;
        padding: 20px 0;

        h3 {
          @include textTitle(600, 20px, 24px, #280d5f);
          font-family: Montserrat;
          padding-bottom: 20px;
        }
      }
    }
  }
}
.api-logo {
  .header-item-border {
    display: none !important;
  }
}
.disconnect-button-section {
  text-align: right;

  button {
    @include textTitle(600, 16px, unset, #280d5f);
    font-family: Montserrat;
    text-transform: capitalize;
    margin-top: 55px;
  }

  margin-top: 10px;
}

.balance-draw {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  p {
    @include textTitle(600, 20px, 34px, #280d5f);
    font-family: Montserrat;
  }

  div {
    display: flex;
    align-items: center;

    span {
      @include textTitle(600, 16px, 20px, #280d5f);
      font-family: Montserrat;
      margin-left: 10px;
    }
  }
}

.balance-draw-coin {
  display: flex;
  flex-direction: column;
}

.account-drawwer {
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-bottom: 20px;

  h3 {
    @include textTitle(600, 20px, 24px, #280d5f);
    font-family: Montserrat;
  }
}

.test {
  padding: 0 13px;
}

.Drawer_drawer__3muYw {
  overflow: hidden !important;
}

.test {
  overflow-y: auto !important;

  h3 {
    @include textTitle(600, 20px, 24px, #280d5f);
    font-family: Montserrat;
  }

  .account-addres {
    @include flexMixin(center, space-between);

    p {
      @include textTitle(600, 20px, 24px, #280d5f);
      font-family: Montserrat;
    }
  }
}

.my-nft {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  .nft-title {
    display: flex;
    align-items: center;

    span {
      padding-left: 11px;
      @include textTitle(600, 16px, unset, #280d5f);
      font-family: Montserrat;
    }
  }

  .nft-first {
    padding-top: 20px;
    margin-top: 15px;
    display: flex;
    width: 100%;
    align-items: center;
    gap: 21px;
    border-top: 1px solid #280d5f;
  }
}

.nft-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;

  .choco-title {
    @include flexMixin(center, space-between);
    @include textTitle(600, 16px, 20px, #280d5f);
    font-family: Montserrat;
    width: 73%;
    margin-bottom: 2px;
  }

  .choco-features {
    border: 1px solid #c76aca80;
    padding: 6px;
    border-radius: 10px;
    @include flexMixin(center, space-between);

    span {
      @include textTitle(600, 16px, unset, #280d5f);
      font-family: Montserrat;
    }

    .buy-button {
      @include textTitle(600, 10px, unset, #8f85a8);
      font-family: Montserrat;
      padding: 3px 21px;
      border: none;
      outline: none;
      border-radius: 20px;

      background: #d4d5d9;
      cursor: pointer;
    }

    .claim-button {
      @include textTitle(600, 10px, unset, #8f85a8);
      font-family: Montserrat;
      padding: 3px 8px;
      border: none;
      outline: none;
      border-radius: 20px;
      background: #d4d5d9;
    }

    span:nth-child(3) {
      padding-left: 22px;
    }
  }
}

.staked-nfts {
  display: flex;
  width: 100%;
  flex-direction: column;
  max-height: 100px;
  overflow-y: scroll;
  padding-right: 15px;
  border: 1px solid #c76aca;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
    border: 1px solid #c76aca;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #280d5f;
    border-radius: 4px;
  }
}

.staked-nfts-items {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  &:nth-child(1) {
    margin-top: 0;
  }

  span {
    @include textTitle(600, 16px, unset, #280d5f);
    font-family: Montserrat;
    text-align: center;
  }
}

.buy-nft_button {
  background: #c76aca;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 4px 6px;
  font-family: Montserrat;
  @include textTitle(600, 10px, 12px, #ffffff);
  font-family: Montserrat;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.nft-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.css-79ws1d-MuiModal-root {
  .css-1wnsr1i {
    border-radius: 30px;
    background: #effaff;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 32px 14px;
    border: none;

    .css-1x51dt5-MuiInputBase-input-MuiInput-input {
      border: 2px solid #280d5f;
      background: #effaff;
      border-radius: 10px;
      box-shadow: 0px 4px 4px 0px #00000040;
      @include textTitle(400, 18px, unset, #280d5f63);
      font-family: Montserrat;
      padding: 19px 0 19px 10px;
      width: 150px;
    }

    .css-1tcs2i1-MuiInputBase-root-MuiInput-root {
      &:before {
        border-bottom: none;
      }
    }
  }

  .css-1tcs2i1-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }

  .css-1tcs2i1-MuiInputBase-root-MuiInput-root:after {
    border-bottom: none;
  }
}

.modal-box {
  display: flex;
  justify-content: space-between;
  //align-items: center;

  p {
    @include textTitle(600, 18px, unset, #280d5f);
    font-family: Montserrat;
    margin-top: 10px;
  }

  .active-unfreeze {
    padding: 5px 10px;
    background: linear-gradient(180deg, #03a66d 0%, #03a66d 100%);
    box-shadow: 5px 0px 5px 0px #0000001a;
    @include textTitle(600, 9px, unset, #fff);
    font-family: Montserrat;
    border-radius: 20px;
    //background: linear-gradient(180deg, #03a66d 0%, #03a66d 100%);
    cursor: pointer;
    margin-top: 10px;
  }

  .passive-infreeze {
    padding: 5px 10px;
    background: linear-gradient(180deg, #03a66d 0%, #03a66d 100%);
    box-shadow: 5px 0px 5px 0px #0000001a;
    @include textTitle(600, 9px, unset, #280d5f);
    font-family: Montserrat;
    border-radius: 20px;
    //background: linear-gradient(180deg, #03a66d 0%, #03a66d 100%);
    cursor: pointer;
  }
}

.modal-percent {
  display: flex;
  justify-content: center;
  margin-top: 10px;

  .percentage {
    display: flex;
    flex-direction: column;
    padding-right: 13px;
    cursor: pointer;

    &:last-child {
      padding: 0;
    }

    span {
      @include textTitle(600, 12px, unset, #280d5f);
      font-family: Montserrat;
      text-align: center;
    }

    .percent-active {
      width: 27px;
      height: 4px;
      background-color: #03a66d;
    }

    .percent-inactive {
      width: 27px;
      height: 4px;
      background: #280d5f80;
    }
  }
}

@keyframes float {
  0% {
    // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    // box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

@keyframes floats {
  0% {
    transform: translate(20px);
  }
  50% {
    // box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translate(20px);
  }
  // 0% {
  //   // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  //   transform: translatey(-10px);
  // }
  // 50% {
  //   // box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
  //   transform: translatey(-20px);
  // }
  // 100% {
  //   // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  //   transform: translatey(15px);
  // }
  // 0% {
  //   // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  //   transform: translate(30px);
  // }
  // 50% {
  //   // box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
  //   transform: translatey(0px);
  // }
  // 100% {
  //   // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  //   transform: translatey(15px);
  // }
}

@keyframes floatL {
  0% {
    // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(5px);
  }
  50% {
    // box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-5px);
  }
  100% {
    // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(5px);
  }
  // 0% {
  //   // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  //   transform: translate(30px);
  // }
  // 50% {
  //   // box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
  //   transform: translatey(-20px);
  // }
  // 100% {
  //   // box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
  //   transform: translatey(15px);
  // }
}

.columns {
  .about-header-parent {
    max-width: 75%;
  }
}

@media screen and (max-width: 1100px) {
  .row-header-bg-fixed {
    border-radius: 0px 0px 20px 20px;
    background-color: white;
    position: fixed;
    width: 100%;
    z-index: 999;
    transition: 0.5s all ease-in-out;
    top: 0;

    .burger {
      display: block;
    }
  }
  .content-container-about {
    .columns {
      max-width: 100%;
      width: 100%;
      flex-basis: inherit;

      .about-header-parent {
        max-width: 75%;
        padding-top: 125px;
      }

      .about-header-sub-parent {
        padding-top: 0;
      }

      .about-header-sub-parent {
        max-width: 100%;

        .about-text {
          @include textTitle(400, 24px, 28px, #ffffff);
          font-family: Montserrat;
        }
      }
    }
  }
  .swt-nft-buttons {
    button:nth-child(1) {
      margin-bottom: 20px;
    }
  }
  .nav-and-connect {
    .connect-btn-wallet {
      width: 258px;
      height: 48px;
      @include textTitle(400, 24px, 24px, #fff);
    }
  }
  .page-content {
    .icons-and-animate-box {
      .swt-coins {
        height: 200px;
      }
    }

    .icons-and-animate-box-mobile {
      .swt-coins {
        height: 200px;
      }
    }

    .header-parent-section {
      .buy-sweet-open {
        .button-ido {
          padding: 15px 25px;
        }

        .buy-sweet-btn {
          padding: 15px 46px;
          font-size: 20px;
        }

        .button-ido {
          font-size: 20px;
        }
      }
    }
  }
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .social-icons-header {
    a {
      width: 50px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .header-parent-section {
    .page-content {
      @include pageContent(660px);
    }

    .logo-sweet-desktop {
      display: none;
    }

    .logo-sweet-mobile {
      display: block;
    }

    .nav-container {
      position: absolute;
      right: 0;
      background: #fff;
      color: black;
      width: 100%;
      top: 0;
      opacity: 0;
      transform: translateY(-100%);
      transition: 0.5s all;
      padding: 10px 0px;

      .info-content {
        opacity: 0.5;
        display: flex;
        flex-direction: column;
        align-items: center;

        .info-item-h2 {
          font-size: 28px;
          color: rebeccapurple;
        }
      }

      // transform: translateX(-100%);
    }

    .show-nav-panel {
      border-radius: 0px 0px 20px 20px;
      transition: 0.5s all;
      top: 75px;
      z-index: 0;
      opacity: 1;
      transform: translateY(0px);
      .info-content {
        opacity: 1;
      }

      // transform: translateX(0%);
    }

    .burger {
      button {
        display: grid;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        //
        //svg {
        //  height: 2em;
        //  width: 2em;
        //}
        .burger-line {
          transition: 0.4s ease-in-out;
          transform: rotate(0);
        }

        .open {
          background: transparent;
          transition: 0.7s;
        }

        .rotate1 {
          position: absolute;
          transform: rotate(45deg);
        }

        .rotate2 {
          position: absolute;
          transform: rotate(-45deg);
        }

        .rotate-back1 {
          //position: absolute;
          //top: -15px;
          transform: rotate(180deg);
        }

        .rotate-back2 {
          //top: 15px;
          //position: absolute;
          transform: rotate(-180deg);
        }
      }
    }
  }
  .page-content {
    .icon-button {
      display: flex;
      flex-direction: column;
      .burger-line2 {
        margin: 5px 0px;
      }
    }
  }
  .burger-line {
    width: 55px;
    height: 6px;
    border-radius: 3px;
    background-color: #b449db;
  }
  .header-noneBackgr {
    border-radius: unset;
    position: fixed;
    transition: 0.5s all ease-in-out;
    background-color: white;
    width: 100%;
    z-index: 999;
    top: 0;

    .burger {
      display: block;
    }
  }
  .row-header-bg {
    top: -500px;
    position: absolute;
    transition: 0.5s all ease-in-out;
    background-color: white;
    border-radius: 0px 0px 20px 20px;

    .logo-sweet-desktop {
      display: none;
    }

    .burger {
      display: block;
    }
  }
  .page-content {
    .content-container-about {
      margin: 40px auto;
      margin-left: 0;
      text-align: justify;
      width: 100%;
      margin-top: 0;

      .buy-sweet-open {
        justify-content: center;
      }

      // .columns {
      .stack-mobile {
        margin: 60px auto;
        display: block !important;
      }

      // }
      .stack-card {
        display: none;
        max-width: 79%;
        height: 217px;
      }

      .stack-values {
        height: 100%;
        justify-content: center;
        align-items: center;
        gap: 10px;

        div {
          span {
            @include textTitle(400, 16px, 16px, #280d5f);
          }
        }
      }

      .about-header {
        font-size: 34px;
        text-align: center;
      }

      .icons-and-animate-box {
        @include flexMixin(center, center);
        align-items: center;
        flex-direction: column;
      }

      .icons-and-animate-box-mobile {
        @include flexMixin(center, center);
        align-items: center;
        flex-direction: column;
      }

      .swt-coins {
        margin-left: auto;
        margin-right: auto;
        // left: -115px;
        right: 0;
        text-align: center;
        bottom: 0;
        width: 300px;
        margin: 50px 0 0 0;

        .swt1 {
          width: 150px;
          left: 70px;
        }

        .swt2 {
          left: 0px;
          width: 150px;
          top: 80px;
        }

        .swt3 {
          left: 115px;
          top: 170px;
          width: 100px;
        }
      }
    }
  }
  .about-header-parent {
    width: 100%;
    margin: 0 auto;

    .about-text {
      text-align: center;
      font-size: 22px;
      line-height: 28px;
    }
  }
  .drawer-full-content {
    .drawwer-box {
      max-width: 420px;
    }
  }
  .nav-container {
    top: 96px;
  }
}

@media screen and (max-width: 500px) {
  .stacking-leadbord-content {
    margin: 50px auto 0 !important;
  }

  .disconnect-button-section {
    button {
      margin-bottom: 95px;
    }
  }
  .header-parent-section {
    .nav-container {
      .info-content {
        grid-gap: 0;

        .info-item-border {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .info-item-h2 {
          font-size: 24px;
        }
      }
    }

    .content-container-about {
      margin-top: 80px !important;

      .columns {
        .about-header-parent {
          padding-top: 0;
        }
      }
    }

    .nav-container {
      //top: 78px;
    }
  }

  .icons-and-animate-box {
    display: none !important;

    .social-icons-header {
      display: none !important;
    }
  }

  .page-content {
    .content-container-about {
      .swt-coins {
        margin: 0 auto;
      }
    }
  }

  .css-79ws1d-MuiModal-root {
    .css-1wnsr1i {
      width: 360px;
    }
  }
  .header-parent-section {
    .logo-sweet-mobile {
      width: 40px;
    }

    .page-content {
      padding: 20px 0px;
      @include pageContent(340px);
      a {
        position: relative;
        //z-index: 999;
      }
    }

    .content-container-about {
      padding: 0;
      margin: 90px auto;

      .stack-mobile {
        margin: 60px auto 50px auto;
      }

      .about-header {
        font-size: 26px;
      }

      .about-text {
        font-size: 14px;
        margin-top: 0;
      }

      .social-icons-header {
        grid-gap: 5px;

        a {
          width: 30px;
          height: 30px;
        }
      }

      .stack-card {
        max-width: 100%;
        height: 161px;
      }

      .buy-sweet-open {
        flex-direction: column;
        display: grid;
      }

      .columns {
        .about-header-parent {
          max-width: 91%;
        }
      }

      .about-header-sub-parent {
        .about-text {
          font-size: 14px;
          letter-spacing: 0.095em;
          line-height: 24px;
        }
      }
    }

    .social-icons-header {
      a {
        display: inline-block;

        img {
          width: 30px;
        }
      }
    }

    .columns {
      width: 100%;
    }
  }
  .swt-nft-buttons {
    button:nth-child(1) {
      margin-bottom: 20px;
    }
  }
  .page-content {
    .header-parent-section {
      .buy-sweet-open {
        gap: 20px;

        .button-ido {
          @include textTitle(400, 20px, 20px, #ffffff);
          padding: 9px 48px;
        }

        .buy-sweet-btn {
          padding: 9px 71px;
          @include textTitle(400, 20px, 20px, #ffffff);
        }
      }
    }

    .content-container-about {
      .social-icons-header {
        margin-top: 50px;
      }

      .stack-values {
        grid-row-gap: 40px;
      }
    }
  }
  // .icon-button {
  //   gap: 4px;
  // }
  .burger-line {
    width: 31px;
    height: 4px;
    border-radius: 2px;
  }
  .nav-and-connect {
    .connect-btn-wallet {
      width: 217px;
      height: 38px;
      @include textTitle(400, 20px, 20px, #ffffff);
    }
  }
  .header-parent-section {
    .burger button {
      //display: grid;
      //gap: 6px;
      gap: 0;

      .burger-line1 {
        margin-bottom: 6px;
        margin-top: 6px;
      }

      .burger-line2 {
        margin: 0;
        transition: 0.1s;
        margin-bottom: 6px;
      }

      .burger-line3 {
        margin-bottom: 0px;
      }
    }
  }
  .main-content {
    .tokeno {
      .card-precent {
        padding: 22px 29px;
      }

      .pool-title-roadmap {
        margin: 50px 0 20px 0;
      }

      .item-title {
        font-size: 16px;
      }
    }

    .roadMap_container {
      .quarters-item {
        &:nth-child(1) {
          .title-quarter {
            padding: 12.5px 0;
          }
        }

        &:nth-child(2) {
          .title-quarter {
            padding: 12.5px 0;
          }
        }

        &:nth-child(3) {
          .title-quarter {
            padding: 12.5px 0;
          }
        }

        &:nth-child(4) {
          .title-quarter {
            padding: 12.5px 0;
          }
        }
      }
    }
  }
  .parent-item-card-center {
    .item-card {
      .liner {
        height: 12px;
      }
    }
  }
  .quarters-item {
    .title-quarter {
      h3 {
        @include textTitle(400, 26px, 20px, #ffffff);
      }
    }
  }
  .swt-nft-buttons {
    button:nth-child(1) {
      margin-bottom: 20px;
    }
  }
  .nav-container {
    top: 84px;
  }
  .page-content {
    .content-container-about {
      .swt-coins {
        right: -20px;
        top: 55px;

        .swt1 {
          width: 119px;
          left: 78px;
          top: 9px;
        }

        .swt2 {
          left: 57px;
          width: 90px;
          top: 67px;
        }

        .swt3 {
          left: 111px;
          top: 111px;
          width: 76px;
        }
      }
    }
  }
}

@media screen and (min-width: 500px) {
  .page-content {
    .content-container-about {
      .buy-sweet-open {
        display: grid;
        grid-auto-flow: column;
      }
    }
  }

  .social-icons-header-mobile {
    grid-gap: 5px;
    display: none !important;
  }

  .icons-and-animate-box-mobile {
    display: none !important;
  }
}

.uYscM {
  width: 100vw !important;

  z-index: 99 !important;
}
