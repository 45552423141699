@import "./assets/scss/definitions/mixins";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  list-style: none;
}
body {
  padding: 0 !important;
}
.load {
  opacity: 0.5;
}
.font-loader {
  font-family: Montserrat;
}
.content {
  overflow: hidden;
}

iframe {
  display: none;
}
.content {
  overflow: hidden;
}
body {
  font-family: Montserrat;
  overflow-y: scroll !important;
  -webkit-font-smoothing: subpixel-antialiased;
}

@font-face {
  font-family: Arciform Sans;
  src: url("./assets/fonts/Arciform.otf");
}
@font-face {
  font-family: Montserrat;
  src: url("./assets/fonts/Montserrat-VariableFont_wght.ttf");
}

@font-face {
  font-family: Product-Sans;
  src: url("./assets/fonts/Product Sans Regular.ttf");
}

.rotate {
  animation: rotation 8s infinite linear;
  position: absolute;
  z-index: 1000;
  width: 200px;
}

.loaded-content-loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
