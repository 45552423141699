// @import url("./_variables.scss");

@mixin pageContent($max) {
  max-width: $max;
  width: 100%;
  margin: 0 auto;
}

@mixin textTitle($fontW, $textsize, $lineH, $white) {
  font-style: normal;
  font-weight: $fontW;
  font-size: $textsize;
  line-height: $lineH;
  color: $white;
  font-family: Arciform Sans;
}

@mixin buttonMixin($bgConntext) {
  background: $bgConntext;
  box-shadow: 0px 2px 0px #07442e;
  border-radius: 20px;
}

@mixin flexMixin($align, $justify) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
}

@mixin wrapFlex() {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@mixin precent($itemPrecent) {
  width: $itemPrecent;
  height: 100%;
  background: linear-gradient(90deg, #7c4fc9 0%, #ea7acb 100%);
  border-radius: 40px;
}

@mixin Input {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  border: 1px solid #7b4fc7;
  //backdrop-filter: blur(40px);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #7b4fc7;
}

@mixin quarterTitle($padd, $bgCol) {
  text-align: center;
  padding: $padd;
  background-color: $bgCol;
  font-family: Arciform Sans;
  border-radius: 30px 30px 0px 0px;
  color: #ffffff;
}

@mixin btnAnimate {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
