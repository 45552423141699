@import "../../assets/scss/definitions/mixins";

footer {
  // margin-top: 86px;
  background: #e0f6f9;
  .buy-sweet-btn {
    cursor: pointer;
  }
}

.youtube-about-video {
  //padding-bottom: 86px;
  padding: 20px 0 80px 0;
}

.footer-content {
  background: linear-gradient(109.28deg, #4c248a -0.18%, #7b4dcf 99.11%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // border-top-left-radius: 110% 15%;
  // border-top-right-radius: 110% 15%;
  clip-path: ellipse(93% 100% at 48.28% 100%);

  .footer-nav {
    strong {
      @include textTitle(700, 20px, 24px, #ffffff);
      font-family: Montserrat;
    }
  }

  //   position: relative;
  //   top: -50px;
  //   padding-top: 86px;

  .footer {
    @include pageContent(1067px);

    .link-sections {
      display: flex;
      justify-content: center;
    }
  }

  .footer-nav {
    @include flexMixin(start, space-evenly);
    margin-top: 75px;
    margin-bottom: 60px;

    li {
      margin: 14px 0px;
    }

    a {
      text-decoration: none;
      @include textTitle(400, 18px, 22px, white);
      font-family: Montserrat;
    }
  }

  strong {
    @include textTitle(700, 20px, 24px, white);
    font-family: Montserrat;
    margin-bottom: 11px;
    display: inline-block;
  }

  .img-block-top {
    text-align: center;

    .logo-sweet-desktop {
      margin-top: 15px;
    }
  }

  .line {
    width: 92%;
    height: 4px;
    background-color: white;
    margin: 20px auto 9px;
  }

  .line-footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .copyright {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 41px;
  }

  .img-block-mobile {
    display: none;
  }

  .logo-sweet-mobile {
    display: none;
  }

  .social-icons {
    section {
      display: grid;
      grid-auto-flow: column;
      gap: 4px;

      .socials_wrap {
        display: flex;
        gap: 4px;
      }

      li {
        a {
          img:nth-child(2) {
            display: none;
          }

          &:hover {
            & img:nth-child(1) {
              display: none;
            }

            & img:nth-child(2) {
              display: block !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .footer-content {
    .footer-nav {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 20px;
      margin-bottom: 50px;
      margin-top: 0;

      ul {
        &:nth-child(1) {
          margin-top: 27px;
        }

        li {
          margin: 10px 0;
        }
      }

      strong {
        margin-bottom: 0;
        margin-top: 0;
        @include textTitle(700, 30px, 37px, #ffffff);
        font-family: Montserrat;
      }

      a {
        @include textTitle(400, 26px, 32px, #ffffff);
        font-family: Montserrat;
      }
    }

    .copyright {
      font-size: 26px;
    }
  }
  .img-block-mobile {
    a {
      img {
        width: 294px;
      }
    }
  }
  .footer-link {
    display: none;
  }
  .footer-section {
    .img-block-mobile {
      display: flex;
      justify-content: center;
    }

    .line {
      margin: 16px auto 16px;
      height: 1px;
    }
  }
  .img-block-top {
    display: none;
  }
  .youtube-about-video {
    padding: 20px 20px 30px 20px;
  }
  .page-content {
    .second-about-content {
      margin-top: 60px;
    }
  }
}

@media screen and (max-width: 500px) {
  .footer-content {
    .footer-nav {
      strong {
        margin-bottom: 20px;
        font-size: 26px;
      }

      a {
        line-height: unset;
        font-size: 20px;
      }

      ul {
        li {
          margin: 10px 0 22px 0;
        }
      }
    }

    .copyright {
      font-size: 16px;
    }
  }
  .img-block-mobile {
    a {
      img {
        width: 108px;
      }
    }
  }
  .second-main-content {
    .stacking-leadbord-content {
      margin-top: 0 !important;
      .pool-title {
        margin-top: 0 !important;
        .stake-title {
          font-size: 30px;
          margin-top: 40px;
        }
      }
    }
  }
}
