@import "../../assets/scss/definitions/mixins";
.header {
  // min-height: 64px;
  //border-bottom: 1px solid #280D5F;
}


.Drawer_drawer__21u49 {
  min-height: 100vh;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1);
}

.overlayOpen {
  opacity: 1;
  z-index: 0;
  visibility: visible;
}

.overlayHidden {
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.disconnect-account {
  margin: 10px 0;
}
.Drawer_header__AB89g {
  display: flex;
  justify-content: space-around;
}
.drawer {
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;

  width: 300px;
  flex-shrink: 0;

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  min-height: 100vh;
  background: rgba(239, 250, 255, 0.5);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px 0px 0px 30px;
  backdrop-filter: blur(5px);
}

.hidden {
  visibility: hidden;
  width: 240px;
  transform: translateX(400px);
  flex-shrink: 0;
  transition: 325ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.animate {
  visibility: visible;
  transform: none;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.left {
  left: 0;
  right: auto;
}

.right {
  right: 0;
  left: auto;
}
