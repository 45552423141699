// clip-path: polygon(47% 46%, 0 75%, 100% 75%);
@import "../../assets/scss/definitions/mixins";

// live pools
@font-face {
  font-family: Montserrat;
  src: url("../../assets/fonts/Montserrat-VariableFont_wght.ttf");
}

.seed-background {
  //clip-path:  polygon(50% 0%, 100% 0, 100% 79%, 78% 76%, 34% 54%, 0% 32%, 0 0);
  clip-path: ellipse(136% 75% at 122.2% 0%);
  position: absolute;
  width: 100%;
  height: 900px;
  background: linear-gradient(
    179.77deg,
    rgba(90, 208, 220, 0) 0.2%,
    rgba(70, 255, 255, 0.3468) 131.26%
  );
}

.first-main-content {
  position: relative;
  top: -80px;
  border-bottom-left-radius: 103% 17%;
  border-bottom-right-radius: 103% 17%;
}

.playBtn {
  cursor: pointer !important;
}

.element {
  background: rgba(229, 224, 246, 1);
  clip-path: ellipse(114% 100% at 46.8% 0%);
}

.second-main-content {
  background: linear-gradient(
    179.77deg,
    rgba(90, 208, 220, 0) 0.2%,
    rgba(139, 224, 233, 0.3468) 131.26%
  );
}

.unique-stake {
  justify-self: end;
}

.live-pools-conetnt {
  padding-bottom: 13px;
  clip-path: ellipse(93% 100% at 48.28% 100%);
  background: #e5e0f6;

  .page-content {
    @include pageContent(1067px);
  }

  .pool-title {
    text-align: center;
    padding-top: 160px;
    @include textTitle(400, 40px, 50px, #7645d9);
  }

  .live-pools {
    background: #effaff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    height: 190px;
    width: 60.5%;
    margin: 50px auto 0;
    @include flexMixin(center, center);
  }

  .pool-soon {
    @include textTitle(500, 20px, 24px, #280d5f);
    font-family: Montserrat;
  }
}

.second-roadmap-title {
  .pool-title {
    margin: 0 0 50px 0;
  }
}

.warning-text-error {
  @include textTitle(400, 12px, 15px, #dc0000);
  font-family: Montserrat;
  margin-top: 5px;
  text-align: right;
}

// tokeno
.locked-tokens {
  display: flex;
  justify-content: center;
  align-items: center;

  -webkit-align-items: center;
  height: 100%;

  span {
    @include textTitle(400, 12px, unset, #280d5f);
    margin: 0px 5px;
  }
}

.tokeno {
  .pool-title-roadmap {
    margin: 100px 0px 0px 0px;
    text-align: center;
    @include textTitle(400, 40px, 40px, #7645d9);
  }

  @include pageContent(1067px);
  padding-bottom: 105px;

  .title-tokeno {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    margin: 100px 0px 50px 0px;

    p {
      @include textTitle(400, 40px, 40px, #7645d9);
    }

    .total-tokeno {
      font-family: "Arciform Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
    }
  }

  .parent-item-card-center {
    display: grid;
    grid-template-columns: repeat(3, minmax(263px, auto));
    grid-gap: 110px 30px;

    .item-card {
      &:nth-child(3) {
        .item-title {
          width: 100%;
        }
      }

      &:nth-child(8) {
        .item-title {
          &:nth-child(1) {
            width: 100%;
          }
        }

        .liner {
          flex-direction: row;
        }
      }

      &:nth-child(6) {
        .liner {
          flex-direction: row;
        }
      }
    }
  }

  .card-precent {
    width: 100%;
    margin: 0 auto;
    padding: 70px;
    background: #effaff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
  }

  .item-title {
    display: flex;
    flex-direction: row;
    width: 44%;
    @include textTitle(400, 16px, 16px, #280d5f);
    margin-bottom: 8px;
    letter-spacing: 0;
    align-items: center;

    p {
      margin-left: 18px;
      @include textTitle(600, 16px, 20px, #280d5f);
      letter-spacing: 0;
      font-family: Montserrat;
    }
  }

  .liner {
    width: 263px;
    height: 15px;
    background: #ffffff;
    border-radius: 40px;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    p {
      border: 1px solid #cacaca;
    }

    .item-precent25 {
      @include precent(66px);

      height: 100%;
      background: linear-gradient(90deg, #7c4fc9 0%, #ea7acb 100%);
      border-radius: 40px;
    }

    .item-precent19 {
      @include precent(53px);
      height: 100%;
      background: linear-gradient(90deg, #7c4fc9 0%, #ea7acb 100%);
      border-radius: 40px;
    }

    .item-precent18 {
      @include precent(48px);
      height: 100%;
      background: linear-gradient(90deg, #7c4fc9 0%, #ea7acb 100%);
      border-radius: 40px;
    }

    .item-precent15 {
      @include precent(41px);
      height: 100%;
      background: linear-gradient(90deg, #7c4fc9 0%, #ea7acb 100%);
      border-radius: 40px;
    }

    .item-precent9 {
      @include precent(27px);
      height: 100%;
      background: linear-gradient(90deg, #7c4fc9 0%, #ea7acb 100%);
      border-radius: 40px;
    }

    .item-precent5 {
      @include precent(18px);
      height: 100%;
      background: linear-gradient(90deg, #7c4fc9 0%, #ea7acb 100%);
      border-radius: 40px;
    }

    .item-precent4 {
      @include precent(16px);
      height: 100%;
      background: linear-gradient(90deg, #7c4fc9 0%, #ea7acb 100%);
      border-radius: 40px;
    }

    .item-precent4 {
      @include precent(16px);
      height: 100%;
      background: linear-gradient(90deg, #7c4fc9 0%, #ea7acb 100%);
      border-radius: 40px;
    }

    .item-precent1 {
      @include precent(5px);
      height: 100%;
      background: linear-gradient(90deg, #7c4fc9 0%, #ea7acb 100%);
      border-radius: 40px;
    }
  }
}

.calc-top-section {
  .war-text {
    margin-top: 5px;
    @include textTitle(400, 12px, 15px, #dc0000);
    font-family: Montserrat;
    display: flex;
    justify-content: flex-end;
  }

  .until-and-error-text {
    margin-top: 5px;
    @include textTitle(500, 12px, 15px, #dc0000);
    text-align: right;
    font-family: "Montserrat";
  }
}

// swiper
.swiper-pagination-bullet-active {
  background: #4c248a !important;
}

.swiper-wrapper {
  height: 300px !important;
  padding: 20px 0px;
}

.swiper-pagination {
  bottom: -3px !important;
}

.calculator-left-align-footer {
  margin-top: 5px;
}

.swiper-button-next {
  position: fixed !important;
  right: 15px !important;
  color: white !important;
}

.swiper-button-prev {
  position: fixed !important;
  left: 15px !important;
  color: white !important;
}

.swiper-button-disabled {
  display: none !important;
}

.calculator-conetnt {
  @include pageContent(1067px);

  .content-container {
    @include flexMixin(center, space-between);
  }

  .pool-title {
    @include textTitle(400, 40px, 50px, #7645d9);
    padding: 85px 0 50px 0;
  }

  .calc-columns {
    //max-width: 385px;
    width: 100%;
    background: #effaff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    overflow: hidden;
  }

  .columns {
    max-width: 400px;
    height: 550px;
  }

  .col-calculator {
    width: 50%;
  }

  .header-tabs {
    .MuiTabs-flexContainer {
      justify-content: space-between;
    }

    button {
      padding: 7px;
      @include textTitle(unset, 10px, 17px, #a35ac8);
      font-family: Montserrat;

      border-top-right-radius: 25px;
      border-top-left-radius: 15px;
    }

    .Mui-selected {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    }
  }

  .swt-calc-title {
    @include textTitle(400, 20px, 24px, #280d5f);
    font-family: Montserrat;
  }

  .swt-calc-coin {
    margin-top: 80px;
  }

  .css-19kzrtu {
    padding: 20px;
  }

  .calculator-sweetcoin {
    margin-top: 30px;
    gap: 12px;
    @include flexMixin(center, space-between);
    display: grid;
    grid-auto-flow: column;
  }

  .max-sweet {
    @include buttonMixin(linear-gradient(180deg, #7c4fc9 0%, #754cbc 100%));
    height: 36px;
    box-shadow: 5px 0px 5px 0px #0000001a;
    border-radius: 20px;
    @include textTitle(400, 20px, 18px, white);
    font-family: Montserrat;
    text-transform: none;
  }

  .css-1laqsz7-MuiInputAdornment-root {
    margin: 0;
  }

  .css-ittuaa-MuiInputAdornment-root {
    margin: 0;
  }

  .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input {
    text-align: center;
    padding: 10px 0px;
  }

  .click-staked-approve {
    button {
      &:active {
        @include btnAnimate();
      }
    }
  }

  .calculator-sweetcoin-input {
    @include Input();
    padding: 7px;
  }
  .calculator-sweetcoin-footer-input {
    @include Input();
    border: 2px solid #7b4fc7;
    padding: 7px;
  }

  .swt-nft-buttons {
    .buy-sweet-btn {
      &:active {
        @include btnAnimate();
      }
    }

    a {
      background: initial;
      box-shadow: initial;
    }
  }

  .calculator-sweetcoin-title {
    @include textTitle(500, 18px, 22px, #280d5f);
    font-family: Montserrat;
  }

  .input-group-days {
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
      border: none;
    }
    .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input {
      height: inherit;
    }
    width: 100px;

    .MuiInputBase-adornedEnd {
      border: 1px solid #7b4fc7;

      .MuiInputAdornment-outlined {
        width: 18px;
        height: 18px;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }

    div {
      border-radius: 10px;
      cursor: pointer;
    }

    .MuiInputBase-adornedEnd {
      padding: 0px 5px;
    }

    .plus-min {
      fill: black;
      cursor: pointer;
    }

    .passive-plus,
    .minus-passive {
      opacity: 0.5;
    }
  }

  .calculator-sweetcoin-text {
    @include textTitle(unset, 18px, 22px, #280d5f);
    font-family: Montserrat;
    font-style: normal;
  }

  .bottom-calc-section {
    margin-top: 20px;

    .calculator-left-align-footer {
      @include textTitle(400, 14px, 17px, #280d5f);
      font-family: Montserrat;
      text-align: right;
    }
  }

  .buttons-group {
    margin-top: 35px;
    @include flexMixin(center, space-between);

    button {
      @include buttonMixin(linear-gradient(180deg, #00ab6b 0%, #076c47 100%));
      box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      @include textTitle(400, 16px, 18px, white);
      padding: 12px 20px;
      font-family: Montserrat;
      text-transform: capitalize;
      font-family: Arciform Sans;
      min-width: 110px;
    }
  }

  .liquid_button {
    button {
      width: 193px;
    }
  }

  .lp-card-section {
    @include textTitle(400, 16px, 24px, #280d5f);
    .calculator-sweetcoin {
      margin-top: 30px;
    }
    .swt-input {
      @include Input();
      border: 2px solid #7b4fc7;
      padding: 7px;
      input {
        border: none;
        margin: 0;
      }
    }

    .lp-stack {
      margin-top: 35px;
      @include flexMixin(center, flex-end);

      button {
        &:active {
          @include btnAnimate();
        }
      }
    }
    .MuiInputBase-adornedEnd {
      padding: 0px 5px;
    }
    .calculator-left-align-footer {
      margin-top: 5px;
    }
  }

  .lp-input-section {
    margin-top: 128px;
    @include flexMixin(center, space-between);

    .lp-stacking {
      font-family: Montserrat;
      border: 1px solid #7b4fc7;
      backdrop-filter: blur(40px);
      /* Note: backdrop-filter has minimal browser support */
      // padding: 4px 0 5px;
      border-radius: 10px;
      padding: 4px 0px;

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
      .MuiInputBase-adornedEnd {
        padding: 5px;
      }
      .input-group-days {
        .MuiInputBase-input {
          margin: 0 !important;
          border: none !important;
        }

        fieldset {
          display: none !important;
        }
      }
    }
  }

  .add-liquidity {
    .text-title-liquidity {
      @include textTitle(400, 20px, 24px, #280d5f);
      font-family: Montserrat;
    }

    .wbnb-swt,
    .btn-container {
      // @include flexMixin(center, space-between);
      @include flexMixin(center, flex-start);
    }

    .max-liq {
      @include buttonMixin(linear-gradient(180deg, #c76aca 0%, #ac55af 100%));
      @include textTitle(400, 14px, 18px, white);
      font-family: Montserrat, sans-serif;
    }

    .wbnb-swt {
      margin-top: 50px;
    }

    .btn-container {
      margin: 55px 0px;
    }

    .add-liq-stack {
      @include flexMixin(center, flex-end);
      margin-top: 30px;

      button {
        //  @include buttonMixin(linear-gradient(180deg, #03a66d 0%, #03a66d 100%));
        //  @include textTitle(400, 16px, 18px, white);
        //  font-family: Montserrat;
        //  text-transform: capitalize;
        //  padding: 10px;
        &:active {
          @include btnAnimate();
        }
      }
    }
  }
}

.nft-calc {
  h2 {
    @include textTitle(500, 18px, unset, #280d5f);
    font-family: Montserrat;
    margin-bottom: 10px;
  }

  .bottom-calc-section {
    display: none;
  }
}

.nft-cards-section {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, minmax(70px, 80px));
  justify-content: space-around;
  gap: 20px;
  border: 1px solid #d9abe2;
  border-radius: 10px;
}

.nft-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 60px;
  background: rgba(136, 161, 225, 0.25);
  border: 2px solid #d9abe2;
  border-radius: 20px;
  border: 2px solid #d9abe2;
}

.nft-checkblock {
  display: grid;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.nft-check {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #d9d9d933;
  border: 1px solid #280d5f;
  cursor: pointer;
  margin: 0 auto;
}

.swt-nft-button {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  button {
    padding: 0 24px;
    height: 36px;
    border-radius: 20px;
    background: linear-gradient(180deg, #00ab6b 0%, #076c47 100%);
    @include textTitle(400, 16px, unset, #ffffff);
    @include flexMixin(center, center);
    text-transform: none;

    &:active {
      @include btnAnimate();
    }
  }
}

// roadmap
.roadMap_container {
  .roadmap-inner {
    .quarters-item {
      .quarters-item-child {
        .title-quarter1 {
          background-color: #7c4fc9;
        }

        .title-quarter2 {
          background-color: #a35ac8;
        }

        .title-quarter3 {
          background-color: #c76aca;
        }

        .title-quarter4 {
          background-color: #ea7acb;
        }

        p {
          padding: 0 0 0 20px;
        }

        ul {
          li:first-child {
            margin-top: 0;
          }

          li:last-child {
            margin-bottom: 0;
          }
        }

        ul {
          li {
            display: grid;
            grid-auto-flow: column;
            justify-content: start;
          }
        }
      }
    }

    .roadmap-tablet:nth-child(3) {
      .quarters-item:nth-child(2) {
        .quarters-item-child {
          ul {
            padding: 0 15px;

            li {
              &:first-child {
                margin-top: 0px;
                padding-top: 20px;
              }

              &:last-child {
                margin-bottom: 20px;
              }

              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}

.line_div {
  width: 100%;
  height: 1px;
  background-color: #280d5f;
}

.title-quarter {
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    font-weight: 400;
    font-size: 25px;
  }
}

li {
  .close-label {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    gap: 5px;

    img {
      width: 28px;
      height: 28px;
    }

    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
}

#simple-tabpanel-0 {
  input {
    padding: 0;
    margin: 0;
    border: none;
    height: inherit;
    font-size: initial;
  }

  .MuiOutlinedInput-input {
    padding: 10.5px 0px;
  }
  .MuiOutlinedInput-notchedOutline {
    display: none !important;
  }
}

.roadMap_container {
  @include pageContent(1067px);
  display: flex;
  align-items: center;
  flex-direction: column;

  .pool-title-roadmap {
    margin-bottom: 120px;
    @include textTitle(400, 40px, 50px, #7645d9);
  }

  .roadmap-inner {
    @include flexMixin(end, space-between);
    padding: 5px 20px;
    display: flex;
    justify-content: space-around;
    -webkit-justify-content: space-around;
  }

  .roadmap-tablet {
    flex-direction: row;
    display: flex;
    align-items: end;
    -webkit-align-items: flex-end;
    width: 100%;
    justify-content: space-around;
  }

  .quarters-item {
    border-radius: 30px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    min-width: 178px;

    ul {
      background: #fefeff;
      padding: 20px 15px;

      li {
        @include flexMixin(flex-start, initial);
        grid-gap: 12px;
        @include textTitle(400, 12px, 15px, #280d5f);
        font-family: Montserrat;
        margin: 10px 0px;
      }
    }

    &:nth-child(1) {
      .title-quarter {
        @include quarterTitle(13px 0 9.5px 0, #7c4fc9);
      }
    }

    &:nth-child(2) {
      .title-quarter {
        @include quarterTitle(13px 0 9.5px 0, #7c4fc9);
      }
    }

    &:nth-child(3) {
      .title-quarter {
        @include quarterTitle(13px 0 9.5px 0, #7c4fc9);
      }
    }

    &:nth-child(4) {
      .title-quarter {
        @include quarterTitle(13px 0 9.5px 0, #7c4fc9);
      }

      li {
        line-height: 23px;
      }
    }
  }

  .roadmap-slide {
    position: relative;

    width: 100%;
    top: -160px;

    .quarter-divider {
      margin-top: 10px;
      height: 10px;
      background: #7c4fc9;
      border-radius: 25px;
      width: 100%;
      display: flex;
      justify-content: center;
      background: linear-gradient(90deg, #7143c5 -0.61%, #f27ecc 100.33%);

      .divider-line {
        width: 24%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .divider-circle1 {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background-color: #7c4fc9;
          position: absolute;
        }

        .divider-circle2 {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background-color: #a35ac8;
          position: absolute;
        }

        .divider-circle3 {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background-color: #c76aca;
          position: absolute;
        }

        .divider-circle4 {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background-color: #ea7acb;
          position: absolute;
        }
      }

      .circle {
        width: 25px;
        height: 25px;
        border-radius: 15%;
        background: #a35ac8;
      }
    }
  }
}

.partners-component {
  position: relative;
  top: -50px;
}

// slider
.content-slider {
  .carousel {
    overflow: inherit;
    .carousel-item {
      height: inherit !important;
      cursor: pointer;
      &:nth-child(2) {
        top: 30px !important;
        position: relative !important;
      }
      // img {
      //   transition: 1s;
      // }
    }

    // .active {
    //   img {
    //     height: 300px !important;
    //   }
    // }

    // .carousel-item {
    //   :nth-child(2) {
    //     transform: scale(1);
    //   }
    // }
  }
  .price-dragon {
    span {
      @include textTitle(400, 18px, unset, #280d5f);
      font-family: Montserrat;

      &:nth-child(2) {
        font-size: 12px;
        padding-left: 3px;
      }
    }

    p {
      @include textTitle(500, 18px, unset, #280d5f);
      font-family: Montserrat;
    }
  }

  .dragon-neo {
    span {
      @include textTitle(500, 36px, unset, #7c4fc9);
      font-family: Montserrat;
    }

    p {
      @include textTitle(400, 16px, unset, #280d5f);
      font-family: Montserrat;
    }
  }

  .dragon-ario {
    span {
      @include textTitle(500, 36px, unset, #c76aca);
      font-family: Montserrat;
    }

    p {
      @include textTitle(400, 16px, unset, #280d5f);
      font-family: Montserrat;
    }
  }

  .dragon-miro {
    span {
      @include textTitle(400, 36px, unset, #a35ac8);
      font-family: Montserrat;
    }

    p {
      @include textTitle(400, 16px, unset, #280d5f);
      font-family: Montserrat;
    }
  }

  .css-1fzpoyk {
    &:first-child {
      margin-left: -35px;

      // transform: translateY(-50%) translateX(-25%) scale(0.5) !important;
    }

    &:nth-child(3) {
      margin-left: 35px;
      // transform: translateY(-50%) translateX(-55%) scale(0.5) !important;
    }
  }
}

// roadmap-second
.done-label {
  display: flex;
  align-items: center;

  img {
    border-radius: 3px;
    width: 18px;
    height: 18px;

    //margin-top: 2px;
  }
}

.close-label {
  display: flex;
  align-items: center;

  img {
    border-radius: 5px;
    width: 18px;
    height: 18px;
  }
}

.second-roadmap {
  @include pageContent(1067px);

  .pool-title {
    display: flex;
    flex-direction: column;
    text-align: center;

    span {
      &:first-child {
        @include textTitle(400, 36px, 50px, #7645d9);
      }

      &:last-child {
        @include textTitle(400, 32px, 50px, #7645d9);
        padding: 0 50px;
      }
    }
  }

  .quarter-section {
    @include flexMixin(stretch, space-around);

    ul {
      padding: 0 20px 22px 20px;
    }
  }

  .quarters-item {
    background: #fefeff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    min-width: 240px;

    &:nth-child(1) {
      .title-quarter {
        @include quarterTitle(16px 0 13px 0, #7c4fc9);
      }
    }

    &:nth-child(2) {
      .title-quarter {
        @include quarterTitle(16px 0 13px 0, #a35ac8);
      }
    }

    &:nth-child(3) {
      .title-quarter {
        @include quarterTitle(16px 0 13px 0, #c76aca);
      }
    }

    .first {
      margin-top: 12px;
      @include textTitle(400, 18px, 22px, #280d5f);
      font-family: Montserrat;
    }

    .second {
      margin: 6px 0px 21px;
      @include textTitle(600, 16px, 20px, #280d5f);
      font-family: Montserrat;
    }
  }
}

.pinksale-monkey {
  outline: none;
  padding: 20px !important;
  width: 600px !important;
  // height: 400px;
  padding: 0 !important;

  background-size: 100% 100%;
  // bottom: 0;

  .flex-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // margin-top: 40px;
    .buy-sweet-btn-href {
      margin-top: 35px;
    }

    .apeswap-images {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-gap: 15px;

      .apeswapName {
        width: 60%;
      }
    }
  }

  h3 {
    text-align: center;
    font-family: "Arciform Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    letter-spacing: 0.06em;
    color: white;
  }

  .monkey,
  .drag {
    width: 100px;
  }

  .blok-monkey {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .drag-monkey-blok {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-gap: 15px;

      .text-swt {
        width: 60%;
      }
    }
  }
}

// stacking-leaderborad!!

.stacking-leadbord-content {
  @include pageContent(1067px);
  margin: 60px auto 30px;

  .leaderboard-lists {
    padding: 40px 24px;

    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
  }

  .pool-title {
    .stake-title {
      @include textTitle(400, 40px, 50px, #7645d9);
      // margin-top: 40px;
    }
  }

  .titles-staking {
    display: grid;
    grid-template-columns: 40px repeat(3, 150px);
    justify-content: space-around;
    @include textTitle(600, 20px, 20px, #7c4fc9);
    font-family: Montserrat;

    span {
      &:nth-child(3) {
        color: #a35ac8;
      }

      &:nth-child(4) {
        color: #c76aca;
        text-align: center;
      }
    }
  }

  .leaderboard-lists {
    width: 100%;
    margin: 0 auto;

    .titles-staking {
      margin: 10px 0px;
    }
  }

  .leaderboard-list-items {
    margin: 5px 0px;
    display: grid;
    grid-template-columns: 40px repeat(3, 150px);
    justify-content: space-around;
    @include textTitle(400, 16px, 20px, #7c4fc9);
    font-family: Montserrat;
    margin-bottom: 12px;

    &:nth-child(1) {
      color: #63b9f8;
    }

    &:nth-child(4) {
      font-weight: bold;
    }

    &:nth-child(3) {
      font-weight: bold;
      //color: #63b9f8;
    }

    &:nth-child(5) {
      font-weight: bold;
      //color: #63b9f8;
    }

    span {
      text-align: center;
    }
  }

  .stake-divider {
    margin-bottom: 30px;
    background: #280d5f;
    height: 2px;
    border-radius: 20px;
  }
}

.walletconnect-modal__close__wrapper {
  background-color: black !important;
  top: 70px !important;

  right: 20px !important;

  .walletconnect-modal__close__line1,
  .walletconnect-modal__close__line2 {
    border: 2px solid white !important;
  }
}

// about footer
.playBtn-mobile {
  display: none;
  cursor: pointer;
}

.second-about-content {
  @include pageContent(1067px);

  .pool-title {
    margin: 30px 0 50px;
    @include textTitle(400, 40px, 50px, #7645d9);
  }

  .videos-iframe {
    height: 478px;
    @include flexMixin(center, center);
    // background: #fefeff;
    background-image: url("../../svg/about_video.jpg");
    background-size: 100%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;

    video {
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1100px) {
  .roadMap_container {
    .roadmap-slide {
      top: -100px;
    }
  }

  .partners-component {
    top: 30px;
  }

  .apiswap-nav {
    position: absolute;
    right: 0;
    background: #fff;
    color: #000;
    width: 100%;
    top: 83px;
    opacity: 0;
    transition: all 0.5s;
    padding: 10px 0;
  }

  .apiswap-link {
    font-size: 28px !important;
    color: rebeccapurple !important;
  }

  .youtube-about-video {
    .about-video-youtube {
      .pool-title {
        margin: 0 auto;
      }

      .videos-iframe {
        margin-top: 20px;
      }
    }
  }

  .seed-background {
    clip-path: ellipse(67% 55% at 44.98% 0%);
    height: 95%;
  }

  .stacking-leadbord-content {
    .leaderboard-lists {
      max-width: 80%;

      margin: 20px auto 50px;
    }

    .pool-title {
      margin-bottom: 0;
    }
  }

  .first-main-content {
    top: -120px;
  }

  .second-roadmap {
    .quarters-item {
      .quarters-item-child {
        .title-quarter {
          padding: 30px 0;
        }
      }
    }
  }

  .parent-item-card {
    .parent-item-card-center {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px 60px;
    }
  }

  .item-card {
    grid-column: 1 / 4;

    .liner {
      width: auto;
      height: 20px;
    }
  }

  .item-card {
    &:nth-child(2) {
      grid-row: 2;
    }
  }

  .tokeno {
    .card-precent {
      padding: 40px;
      width: 65%;
    }

    .pool-title-roadmap {
      margin: 60px 0 30px 0;
    }

    .item-title {
      width: 33%;
      font-size: 20px;
    }
  }

  .item-card {
    .liner {
      .item-precent25 {
        @include precent(145px);
      }

      .item-precent19 {
        @include precent(112px);
      }

      .item-precent18 {
        @include precent(101px);
      }

      .item-precent15 {
        @include precent(78px);
      }

      .item-precent9 {
        @include precent(55px);
      }

      .item-precent5 {
        @include precent(45px);
      }

      .item-precent4 {
        @include precent(33px);
      }

      .item-precent4 {
        @include precent(33px);
      }

      .item-precent1 {
        @include precent(13px);
      }
    }
  }

  .roadmap-slide {
    .roadmap-inner {
      .roadmap-tablet {
        display: flex;
        gap: 88px;
        flex-direction: row;
        justify-content: center;
        position: relative;

        align-items: baseline;
      }

      .pool-title-roadmap {
        @include textTitle(400, 40px, 50px, #7645d9);
        text-align: center;
        margin-top: 60px;
      }
    }
  }

  .roadMap_container {
    .roadmap-slide {
      .quarter-divider {
        display: none;
      }
    }

    .roadmap-inner {
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .quarters-item {
        min-width: 266px;
        height: 100%;

        .title-quarter {
          font-family: Arciform Sans;
          @include textTitle(400, 24px, 24px, #effaff);
          padding: 16.5px 0;
        }

        .title-quarter1 {
          background-color: #7c4fc9;
        }

        .title-quarter2 {
          background-color: #a35ac8;
        }

        .title-quarter3 {
          background-color: #c76aca;
        }

        .title-quarter4 {
          background-color: #ea7acb;
        }
      }
    }
  }

  .roadMap_container {
    .roadmap-inner {
      .quarters-item {
        .quarters-item-child {
          ul {
            li:last-child {
              margin-bottom: 20px;
            }

            li:first-child {
              margin-top: 20px;
            }
          }
        }
      }

      .roadmap-tablet:nth-child(3) {
        .quarters-item:nth-child(2) {
          .quarters-item-child {
            ul {
              padding: 5px 30px;
            }
          }
        }
      }
    }
  }

  .second-roadmap {
    .quarter-section {
      ul {
        padding: 28px 40px 49px 40px;
      }
    }

    .quarters-item {
      min-width: 330px;

      .first {
        font-size: 20px;
      }

      .second {
        margin: 6px 0 17px;
        @include textTitle(600, 20px, unset, #280d5f);
        font-family: Montserrat;
      }
    }
  }

  .circle-tablet1 {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    z-index: 100;
    bottom: -10px;
    background-color: #7c4fc9;
  }

  .circle-tablet2 {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: red;
    z-index: 100;
    bottom: -10px;
    background-color: #a35ac8;
  }

  .circle-tablet4 {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: red;
    z-index: 100;
    top: -2%;
    background-color: #ea7acb;
  }

  .circle-tablet3 {
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: red;
    z-index: 100;
    top: -2%;
    background-color: #c76aca;
  }

  .quarter-divider-tablet {
    margin-top: 10px;
    height: 10px;
    background: #7c4fc9;
    border-radius: 25px;
    max-width: 616px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background: linear-gradient(90deg, #7143c5 -0.61%, #f27ecc 100.33%);
    z-index: -1;

    .divider-line1 {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;

      .divider-circle {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #7c4fc9;
        display: none;
      }
    }

    .divider-line2 {
      width: 8%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .divider-circle {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #7c4fc9;
        display: none;
      }
    }

    .circle {
      width: 25px;
      height: 25px;
      border-radius: 15%;
      background: #a35ac8;
    }
  }

  .roadmap-tablet-rev {
    .quarters-item-child {
      display: flex;
      flex-direction: column-reverse;
      height: 100%;
    }
  }

  .roadMap_container {
    .quarters-item {
      .quarters-item-child {
        //position: relative;
        .title-quarter1 {
          bottom: -12px;
          border-radius: inherit;
        }

        .title-quarter2 {
          border-radius: inherit;
        }
      }

      ul {
        padding: 5px 19px;

        li {
          @include textTitle(400, 17px, 22px, #280d5f);
          align-items: flex-start;
          font-family: "Montserrat";
        }
      }
    }
  }

  .second-roadmap {
    .quarter-section {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 29px;
      display: grid;
    }

    .pool-title {
      margin: 30px 0 20px 0;

      span {
        &:last-child {
          font-family: Arciform Sans;
          font-size: 30px;
          font-weight: 400;
          line-height: 40px;
          letter-spacing: 0.04em;
          text-align: center;
        }

        &:first-child {
          font-family: Arciform Sans;
          font-size: 40px;
          font-weight: 400;
          line-height: 50px;
          letter-spacing: 0.04em;
          text-align: center;
          margin-bottom: 10px;
        }
      }
    }

    .second-roadmap-title {
      width: 72%;
      margin: 0 auto;
    }
  }

  .second-about-content {
    .videos-iframe {
      width: 84%;
      height: 334px;
      margin: 0 auto;
    }
  }

  li {
    .close-label {
      font-size: 18px;

      img {
        width: 28px;
        height: 28px;
      }
    }
  }

  .calculator-left-align-footer {
    margin-top: 11px;
  }

  .calc-top-section {
    .war-text {
      margin-top: 11px;
    }
  }

  .title-quarter {
    h3 {
      @include textTitle(400, 24px, 24px, #ffffff);
    }
  }

  .roadMap_container {
    .pool-title-roadmap {
      margin-bottom: 30px;
    }
  }

  .live-pools-conetnt {
    .live-pools {
      width: 100%;
      max-width: 500px;
      margin-top: 0;
    }
  }

  .done-label {
    img {
      margin-top: 2px;
    }
  }

  .calculator-conetnt {
    .calc-columns {
      max-width: 500px;
      margin-bottom: 50px;
    }

    .calculator-sweetcoin-title {
      @include textTitle(500, 24px, unset, #280d5f);
      font-family: Montserrat;
    }

    .calculator-sweetcoin-input {
      padding: 10px 22px;
    }

    .calculator-sweetcoin-footer-input {
      padding: 10px;
    }

    .max-sweet {
      padding: 20px 25px;
      font-size: 22px;
    }

    .input-group-days {
      height: 100%;
    }

    .input-group-days {
      .MuiInputBase-adornedEnd {
        height: 100%;
      }
    }

    .css-19kzrtu {
      padding: 17px 17px 44px 17px;
    }
  }

  .MuiInputBase-adornedEnd {
    .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input {
      font-size: 20px;
    }

    .css-ittuaa-MuiInputAdornment-root {
      margin-right: 5px;
    }
  }
}

@media screen and (max-width: 767px) {
  .live-pools-conetnt {
    clip-path: ellipse(138% 100% at 45.6% 100%);
  }

  .element {
    clip-path: ellipse(162% 100% at 44.4% 0%);
  }

  .stacking-leaderboard-section {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;

    .titles-staking {
      display: grid;
      grid-template-columns: 40px repeat(3, 83px);
      justify-content: space-evenly;
      font-size: 12px;

      span {
        white-space: nowrap;
      }
    }

    .leaderboard-list-items {
      margin: 5px 0;
      display: grid;
      grid-template-columns: 40px repeat(3, 80px);
      // justify-content: center;
      font-size: 11px;
    }
  }

  .calculator-conetnt {
    .calculator-sweetcoin-text {
      font-size: 14px;
    }

    .calculator-sweetcoin {
      grid-gap: 10px;
    }

    .warning-text {
      font-size: 13px;
    }

    .pool-title {
      padding: 50px 0 30px 0;
    }

    .header-tabs {
      button {
        font-size: 9px;
      }
    }

    .buttons-group {
      display: flex;
      flex-direction: column;

      //grid-gap: 20px;
      div {
        width: 55%;

        button {
          width: 100%;
          font-family: Arciform Sans;
        }
      }
    }

    .live-pools-conetnt {
      .pool-title {
        padding: 50px 0 0 0;
      }
    }
  }

  .first-main-content {
    .live-pools-conetnt {
      padding-bottom: 16px;

      .page-content {
        @include pageContent(340px);
      }

      .live-pools {
        width: 100%;
        margin-top: 30px;
        margin: 0 auto;
      }
    }
  }

  .col-calculator {
    margin-top: 0px;

    .tire-container {
      // position: relative;
      // left: -10px;

      .card-slide {
        width: 200px !important;

        img {
          width: 100% !important;
        }
      }
    }
  }

  .content-slider {
    // .css-1fzpoyk {
    //   &:first-child {
    //     transform: scale(0.5) !important;
    //   }
    // }

    // .css-1fzpoyk {
    //   &:last-child {
    //     transform: scale(0.5) !important;
    //   }
    // }
  }

  // content-slider .css-1fzpoyk:first-child
  // .card-slide {
  //   width: 200px !important;
  //   img {
  //     width: 100% !important;
  //   }
  // }
  //.roadMap_container {
  //  .roadmap-inner {
  //    // display: flex;
  //    // // flex-wrap: wrap;
  //    // align-items: start;
  //    // grid-gap: 15px;
  //    // justify-content: center;
  //    display: grid;
  //    grid-template-columns: repeat(auto-fit, minmax(265px, auto));
  //    justify-content: center;
  //    align-items: flex-start;
  //    grid-gap: 25px;
  //  }
  //}
  //.second-roadmap {
  //  .quarter-section {
  //    flex-direction: column;
  //    align-items: center;
  //    justify-content: center;
  //    grid-row-gap: 28px;
  //  }
  //
  //  .quarters-item {
  //    max-width: 340px;
  //    width: 100%;
  //  }
  //}
  .calculator-conetnt {
    .col-calculator {
      width: 80%;
    }
  }

  .first-main-content {
    top: -100px;
  }

  .parent-item-card {
    .parent-item-card-center {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px 100px;
    }
  }

  .tokeno {
    .title-tokeno {
      margin: 0;

      button {
        margin: 50px 0 30px 0;
      }
    }
  }

  .item-card {
    grid-column: 1 / 4;

    //grid-row: 1;
    //grid-column: 2;
    .liner {
      width: auto;
    }
  }

  .item-card {
    &:nth-child(2) {
      grid-row: 2;
    }
  }

  .tokeno {
    .card-precent {
      padding: 40px;
      width: 93%;
    }

    .item-title {
      width: 35%;
    }
  }

  .second-about-content {
    .videos-iframe {
      height: 153px;

      .playBtn {
        display: none;
        cursor: pointer;
      }

      .playBtn-mobile {
        display: block;
        cursor: pointer;
      }
    }
  }
}

//partners & media

.partners-conetnt {
  width: 100%;
  display: grid;

  // background: linear-gradient(
  //   179.77deg,
  //   rgba(90, 208, 220, 0) 0.2%,
  //   rgba(139, 224, 233, 0.3468) 131.26%
  // );
}

.hover-show {
  display: none;
  position: absolute;
  //width: 80%;
}

.partners-container {
  display: grid;
  grid-gap: 22px;
  grid-template-columns: repeat(4, minmax(263px, auto));
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  a:nth-child(4) {
    img {
      width: 100px;
    }
  }
}

.partners-section {
  display: flex;
  justify-content: center;
  align-items: center;

  .image-box {
    width: 57%;
    height: 115px;
    background: #fff;
    border-radius: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 4px 0px #00000040;

    img {
      padding: 0px 10px;
    }

    &:hover {
      & img:nth-child(1) {
        display: none;
      }

      & img:nth-child(2) {
        display: block !important;
      }
    }

    span {
      width: 100%;
      height: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;
      position: absolute;

      //img {
      //  width: 80%;
      //}
    }
  }
}

.pool-title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0 50px 0;
  font-family: "Arciform Sans";

  .title-partners {
    text-transform: capitalize;
    @include textTitle(400, 40px, 50px, #7645d9);
  }
}

@media screen and (max-width: 1200px) {
  .tokeno {
    .pool-title-roadmap {
      margin: 60px 0px 20px 0px;

      text-align: center;
      @include textTitle(400, 40px, 40px, #7645d9);
    }
  }

  .calculator-conetnt {
    .content-container {
      justify-content: space-around;
    }
  }

  .partners-container {
    grid-template-columns: repeat(3, minmax(96px, 190px));
    margin: 0 15px;
  }

  .partners-component {
    display: grid;
    justify-content: center;
    margin-bottom: 30px;
  }

  .partners-section {
    display: flex;
    justify-content: center;

    .image-box {
      width: 90%;
      height: 135px;
    }
  }

  .buttons-group {
    display: flex;
    flex-direction: column;
    //grid-gap: 20px;

    div {
      width: 55%;

      button {
        width: 100%;
      }
    }
  }

  .live-pools-conetnt {
    padding-bottom: 14px;

    .pool-title {
      padding: 150px 0 0 0;
    }
  }

  .calculator-conetnt {
    .pool-title {
      padding: 60px 0 30px 0;
    }

    .swt-calc-title {
      @include textTitle(400, 20px, 34px, #280d5f);
      text-align: center;
      font-family: Montserrat;
      letter-spacing: 0.03em;
    }

    .bottom-calc-section {
      width: 85%;
      margin-left: auto;
    }

    .swt-calc-coin {
      margin: 60px 0 43px 0;
    }

    .buttons-group {
      flex-direction: column;
      //gap: 20px;
    }
  }

  .tokeno {
    .title-tokeno {
      margin: 60px 0 30px 0;
    }
  }

  .partners-component {
    .pool-title {
      margin: 40px 0 30px 0;
    }
  }

  .second-about-content {
    .pool-title {
      margin: 0px 0 30px 0 !important;
    }
  }

  .calc-top-section {
    .calculator-sweetcoin-tablet {
      justify-content: right;
      gap: 19px;
    }
  }

  .stacking-leadbord-content {
    margin-top: 60px;
  }
}

@media screen and (max-width: 700px) {
  .pinksale-monkey {
    .flex-card {
      .buy-sweet-btn-href {
        margin-top: 15px;

        button {
          padding: 5px 20px;
        }
      }
    }
  }

  .pinksale-monkey {
    width: 365px !important;

    // height: 75%;
    h3 {
      font-size: 24px;
    }

    .blok-monkey {
      display: flex;
      justify-content: space-evenly;

      // grid-gap: 30px;
      img {
        width: 80px;
      }
    }
  }

  .swap {
    display: none !important;
  }

  .card-pinksale {
    padding: 20px !important;
    max-width: 365px !important;

    .image-card-header {
      grid-gap: 15px;

      img {
        width: 120px;

        &:nth-child(2) {
          width: 40px;
        }
      }
    }

    .text-card-section {
      grid-gap: 50px;

      .participate-sweet {
        font-size: 12px;
      }

      .time {
        font-size: 12px;
      }

      img {
        width: 80px;
        top: 40px;
      }
    }

    .buy-sweet-btn-href {
      button {
        padding: 5px 20px;
      }
    }
  }

  .tire-container {
    .calcualtor-content {
      .buttons-group {
        div {
          margin: 0 auto;
          text-align: center;
        }
      }
    }
  }

  .xswt {
    display: flex;
    justify-content: center;
  }

  .days-stakes {
    display: flex;
    justify-content: center;
  }

  .xswt-coin {
    display: flex;
    justify-content: center;
  }

  .footer-content {
    clip-path: ellipse(160% 100% at 48.28% 100%);
  }

  .roadMap_container {
    .roadmap-inner {
      display: grid;

      .quarter-divider-tablet {
        max-width: 507px;
      }

      .roadmap-tablet {
        gap: 30px;
      }

      .quarters-item {
        min-width: 240px;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .locked-tokens {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      margin: 0px 5px;
      font-size: 12px;
    }
  }

  .element {
    clip-path: ellipse(215% 100% at 50% 0%);
  }

  .page-content .content-container-about .social-icons-header {
    grid-gap: 5px;
  }

  .roadMap_container {
    .roadmap-inner {
      .quarter-divider-tablet {
        max-width: 495px;
      }

      .roadmap-tablet {
        gap: 15px;
      }

      .quarters-item {
        max-width: 210px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .seed-background {
    clip-path: ellipse(170% 50% at 105.3% 0%);
    height: 1500px;
  }

  .col-calculator {
    margin-top: 0;
  }

  .second-roadmap {
    .quarters-item {
      min-width: unset;

      .quarters-item-child {
        .title-quarter {
          padding: 22px 0;
        }
      }
    }
  }

  .second-main-content {
    .stacking-leadbord-content {
      .leaderboard-lists {
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }

  .stacking-leaderboard-section {
    width: 100%;
    margin: 0 auto;

    .leaderboard-list-items {
      margin: 5px 0;
      display: grid;
      justify-content: center;
      font-size: 11px;
    }
  }

  .calculator-conetnt {
    @include pageContent(340px);

    .pool-title {
      font-size: 30px;
    }

    .input-group-days {
      height: 32px;
      width: 94px;

      div {
        svg {
          width: 14px;
          height: 14px;
        }
      }

      .css-152mnda-MuiInputBase-input-MuiOutlinedInput-input {
        font-size: 16px;
        text-align: center;
      }
    }

    .css-19kzrtu {
      .swt-calc-title {
        font-size: 18px;
      }
    }

    .lp-input-section {
      margin-top: 100px;
      display: grid;
      grid-auto-flow: column;
      gap: 10px;
      .lp-stacking {
        padding: 0;
      }
      .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        height: inherit;
        padding: 4px 10px !important;
      }
    }
    .btn-container {
      display: grid;
      grid-auto-flow: column;
      gap: 10px;
    }
  }

  .nft-calc {
    .nft-cards-section {
      justify-content: space-evenly;
    }
  }

  .warning-text-error {
    font-size: 11px;
  }

  .second-roadmap {
    .quarters-item {
      max-width: 250px;
      width: 100%;

      .first {
        margin-top: 0;
      }
    }
  }

  .tokeno {
    .item-card {
      .item-title {
        width: 45%;
      }
    }
  }

  .live-pools-conetnt {
    padding-bottom: 12px;

    .page-content {
      .pool-title {
        padding: 180px 0 30px 0;
      }
    }

    .live-pools {
      height: 156px;
    }
  }

  .calculator-conetnt {
    .tires-calc {
      .pool-title {
        padding: 30px 0 20px 0;
      }
    }
  }

  .tokeno {
    .title-section {
      .title-tokeno {
        margin: 50px 0 30px 0;
      }
    }
  }

  .roadMap_container {
    .roadmap-slide {
      .circle-tablet {
        display: none;
      }

      .roadmap-inner {
        .quarters-item {
          max-width: 240px;
          height: auto;

          .quarters-item-child {
            p {
              padding: 0 0 0 16px;
            }
          }

          ul {
            padding: 5px 8px 5px 19px;
          }
        }

        .roadmap-mobile {
          flex-direction: column;
          align-items: center;
          gap: 20px;
          display: grid;
        }
      }

      .quarter-divider {
        display: flex;
        transform: rotate(90deg) translate(-51%, 650px);
        width: 1380px;
        height: 10px;
        //for testing
        display: none;
      }

      .quarter-divider-tablet {
        display: none;
      }

      .quarters-item-child {
        flex-direction: column;
      }
    }

    .roadmap-slide {
      display: flex;
      justify-content: center;
    }
  }

  .testBox {
    width: 10px;
  }

  .quarter-divider22 {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: green;
    flex-direction: column;
    background: linear-gradient(#7143c5 -0.61%, #f27ecc 100.33%);
    border-radius: 5px;

    .divider-line {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .divider-circle1 {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #7c4fc9;
        position: absolute;
      }

      .divider-circle2 {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #a35ac8;
        position: absolute;
      }

      .divider-circle3 {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #c76aca;
        position: absolute;
      }

      .divider-circle4 {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: #ea7acb;
        position: absolute;
      }
    }
  }

  .second-about-content {
    .videos-iframe {
      width: 88%;
      margin-top: 0px !important;
    }
  }

  .first-main-content {
    .live-pools-conetnt {
      padding-bottom: 14px;
    }
  }

  .partners-component {
    .partners-container {
      grid-template-columns: repeat(3, minmax(75px, 150px));
      margin: 0 15px;

      .partners-section {
        display: flex;
        justify-content: center;

        .image-box {
          width: 96%;
          height: 73px;

          span {
            img {
              width: 80%;
            }
          }

          .hover-show {
            width: 80%;
          }
        }
      }
    }
  }

  .partners-conetnt {
    .partners-component {
      display: grid;
      justify-content: center;
      margin-bottom: 0;

      .pool-title {
        margin: 50px 0 30px 0;

        .title-partners {
          @include textTitle(400, 30px, 40px, #7645d9);
        }
      }
    }
  }

  .second-about-content {
    .about-video-youtube {
      .pool-title {
        margin: 50px 0 30px 0;
      }
    }
  }

  .columns {
    .about-header-parent {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .calculator-conetnt {
    .calc-top-section {
      .calculator-sweetcoin {
        gap: 10px;

        .calculator-sweetcoin-text {
          @include textTitle(unset, 12px, 15px, #280d5f);
          font-family: Montserrat;
        }
      }
    }
    .lp-card-section {
      .css-1x51dt5-MuiInputBase-input-MuiInput-input {
        padding: 0;
        font-size: 16px;
        height: inherit;
      }
      .lp-stack {
        width: 72%;

        margin: 100px auto 0px;
        button {
          width: 100%;
          font-size: 22px;
        }
      }
    }
    .calculator-sweetcoin-input,
    .calculator-sweetcoin-footer-input {
      padding: 2px 10px !important;
    }

    .calc-top-section {
      .warning-text {
        @include textTitle(400, 11px, 12px, #dc0000);
        font-family: Montserrat;
        margin-top: 10px;
      }
    }

    .bottom-calc-section {
      .calculator-left-align-footer {
        @include textTitle(400, 10px, 12px, #280d5f);
        font-family: Montserrat;
      }
    }
    .buttons-group {
      div {
        width: 72%;

        button {
          @include textTitle(400, 22px, 22px, #ffffff);
        }
      }
    }

    .max-sweet {
      height: 30px;
      font-size: 16px;
      padding: 16px 25px;
      display: flex;
      align-content: center;
      justify-self: center;
      -webkit-align-content: center;
    }

    .calculator-sweetcoin {
      margin-top: 40px !important;
      display: grid;
      grid-auto-flow: column;
    }
  }

  .item-card {
    .liner {
      .item-precent25 {
        width: 66px;
      }

      .item-precent19 {
        width: 53px;
      }

      .item-precent18 {
        width: 48px;
      }

      .item-precent15 {
        width: 41px;
      }

      .item-precent9 {
        width: 27px;
      }

      .item-precent5 {
        width: 18px;
      }

      .item-precent4 {
        width: 16px;
      }

      .item-precent4 {
        width: 16px;
      }

      .item-precent1 {
        width: 5px;
      }
    }
  }

  .second-roadmap-content {
    position: relative;
    top: 40px;

    .second-roadmap {
      .second-roadmap-title {
        width: 100%;
      }

      .quarters-item {
        .first {
          margin-top: 14px;
          font-size: 18px;
        }

        .second {
          font-size: 16px;
          margin: 10px 0 17px;
        }

        max-width: 257px;
      }

      .pool-title {
        span {
          &:first-child {
            @include textTitle(400, 24px, 34px, #7645d9);
            padding: 0 33px;
          }

          &:last-child {
            @include textTitle(400, 18px, 24px, #7645d9);
          }
        }

        margin: 50px auto 30px auto;
      }

      .quarter-section {
        ul {
          padding: 6px 28px 20px 28px;
        }
      }
    }
  }

  .until-and-error-text {
    margin-top: 10px;
  }

  .calculator-conetnt {
    .swt-calc-coin {
      margin-bottom: 30px;
      margin-top: 0;
    }
  }

  .calculator-conetnt {
    .swt-calc-coin {
      margin-top: 0 !important;
    }
  }

  .main-content {
    .second-main-content {
      .pool-title {
        margin: 25px 0 15px 0;
        font-size: 30px;
      }
    }
  }

  .tokeno {
    .title-section {
      .title-tokeno {
        margin: 50px 0 20px 0;
        font-size: 30px;
      }
    }

    .title-tokeno {
      p {
        font-size: 30px;
      }
    }

    .pool-title-roadmap {
      font-size: 30px;
    }
  }

  .live-pools-conetnt {
    .page-content {
      .pool-title {
        padding: 60px 0 20px 0;
        font-size: 30px;
      }
    }
  }

  .youtube-about-video {
    padding-bottom: 43px;

    .about-video-youtube {
      .about-us {
        margin: 0px 0 10px 0;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .tokeno {
    padding-bottom: 30px;
  }

  .partners-container {
    grid-template-columns: repeat(3, minmax(96px, 190px));
    margin: 0px 15px;
  }

  .partners-component {
    display: grid;
    justify-content: center;
    margin-bottom: 30px;
  }

  .partners-section {
    display: flex;
    justify-content: center;

    .image-box {
      width: 90%;
      height: 135px;
    }
  }

  .buttons-group {
    display: flex;
    flex-direction: column;
    //grid-gap: 20px;

    div {
      width: 55%;

      button {
        width: 100%;
      }
    }
  }

  .partners-conetnt {
    margin-top: -120px;
  }
}

@media screen and (max-width: 1200px) {
  .live-pools-conetnt {
    padding-bottom: 11px;

    .pool-title {
      padding: 150px 0 20px 0;
    }
  }

  .calculator-conetnt {
    .pool-title {
      padding: 60px 0 20px 0;
    }

    .swt-calc-title {
      @include textTitle(400, 24px, unset, #280d5f);
      text-align: center;
      font-family: Montserrat;
    }

    .bottom-calc-section {
      width: 85%;
      margin-left: auto;
    }

    .swt-calc-coin {
      margin: 60px 0 30px 0;
    }

    .buttons-group {
      flex-direction: column;
      //gap: 20px;
    }
  }

  .tokeno {
    .title-tokeno {
      margin: 60px 0 20px 0;
    }
  }

  .partners-component {
    .pool-title {
      margin: 60px 0 20px 0;
    }
  }

  .second-about-content {
    .pool-title {
      margin: 60px 0 30px 0;
    }
  }

  .calc-top-section {
    .calculator-sweetcoin-tablet {
      justify-content: right;
      gap: 19px;
    }
  }
}

@media screen and (max-width: 700px) {
  .content-api {
    iframe {
      // position: absolute;
      width: 345px !important;
    }
  }

  .roadMap_container {
    .roadmap-inner {
      .quarter-divider-tablet {
        max-width: 507px;
      }

      .roadmap-tablet {
        gap: 30px;
      }

      .quarters-item {
        min-width: 240px;
      }
    }
  }
}

@media screen and (min-width: 1100px) {
  .roadMap_container {
    .roadmap-tablet {
      height: 380px;
    }

    .quarters-item {
      ul {
        li {
          display: grid;
          justify-content: start;
          grid-auto-flow: column;
        }
      }
    }
  }

  .calculator-conetnt {
    .buttons-group {
      .swt-nft-buttons {
        button {
          &:first-child {
          }
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  // .calculator-content {
  //   .lp-card-section {
  //     .calculator-sweetcoin {
  //      margin-top: 40px;
  //   }
  // }
  // }
  .carousel {
    perspective: 300px !important;
  }
  .carousel-item {
    .slider-image {
      width: 220px !important;
      height: 200px !important;
      transform: scale(0.8) !important;
    }
    .dragon-name {
      top: 0 !important;
      left: 0 !important;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    // background-color: red;
    // :nth-child(1) {
    //   .slider-image {
    //     width: 150px !important;
    //     position: relative;
    //   }
    // }
    // :nth-child(2) {
    //   .slider-image {
    //     width: 150px !important;
    //     height: 250px;
    //     position: relative;
    //   }
    // }
    // :nth-child(3) {
    //   .slider-image {
    //     width: 150px !important;
    //     margin: 20px 0px;
    //     position: relative;
    //   }
    // }
  }
  // .content-slider {
  //   .css-1fzpoyk {
  //     &:first-child {
  //       margin-left: -20px;

  //       img {
  //         width: 250px;
  //         // width: 200px !important;
  //       }
  //     }

  //     &:nth-child(2) {
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;

  //       img {
  //         width: 70%;
  //         width: 250px;
  //       }
  //     }

  //     &:nth-child(3) {
  //       margin-left: 40px;

  //       img {
  //         width: 250px !important;
  //         // left: -5px !important;
  //       }
  //     }
  //   }
  // }

  .roadMap_container {
    .roadmap-inner {
      .quarter-divider-tablet {
        max-width: 495px;
      }

      .roadmap-tablet {
        gap: 15px;
      }

      .quarters-item {
        max-width: 210px;
      }
    }
  }
}
