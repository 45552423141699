@import "../../assets/scss/definitions/mixins";

div.btn-container {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

div.btn-container i {
  display: inline-block;
  position: relative;
  top: -9px;
}

label {
  font-size: 13px;
  // color: #424242;
  color: #fff;
  font-weight: 500;
}

.btn-container {
  .max-busd {
    @include buttonMixin(linear-gradient(180deg, #C76ACA 0%, #AC55AF 100%));
    color: white;
    box-shadow: 5px 0 5px rgba(0, 0, 0, 0.1);
    // border-radius: 15px;
    // box-shadow: none;

  }
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner {
  margin: 0px;
  // width: 140px;
  width: 115px;
  height: 33px;
  background: #e0e0e0;
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  display: block;
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  position: absolute;
  font-size: 16px;
  font-weight: 500;
  top: 7px;
  line-height: 20px;
  // right: 20px;
  right: 4px;
  font-family: 'Montserrat';
}

.btn-color-mode-switch>label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  // width: 70px;
  width: 38px;
  // height: 33px;
  background: linear-gradient(0deg, #c76aca, #c76aca),
    linear-gradient(180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 100%);
  border-radius: 30px;
  position: absolute;
  left: 0;
  // left: 2px;
  // top: 2px;
  text-align: center;
  transition: all 0.3s ease;
  // box-shadow: 0px 0px 6px -2px #111;
  // padding: 5px 0px;
  padding: 6px 15px 7px 9px;
  font-size: 16px;
  height: 20px;
}

.btn-color-mode-switch>.alert {
  display: none;
  background: #ff9800;
  border: none;
  color: #fff;
}

.btn-color-mode-switch input[type="checkbox"] {
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner {
  background: linear-gradient(0deg, #d3dce0, #d3dce0),
    linear-gradient(180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%);
  color: #fff;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  // left: 68px;
  left: 49px;
  width: 42px;
  // width: 66px;
  // background: #3c3c3c;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  // left: 20px;
  left: 9px;
}

.btn-color-mode-switch input[type="checkbox"]:checked+label.btn-color-mode-switch-inner {
  // background: #66BB6A; 
  // color: #fff;
}

.btn-color-mode-switch input[type="checkbox"]:checked~.alert {
  display: block;
}

/*mode preview*/
.dark-preview {
  background: #0d0d0d;
}

.dark-preview div.btn-container i.fa-sun-o {
  color: #777;
}

.dark-preview div.btn-container i.fa-moon-o {
  color: #fff;
  text-shadow: 0px 0px 11px #fff;
}

.white-preview {
  background: #fff;
}

.white-preview div.btn-container i.fa-sun-o {
  color: #ffa500;
  text-shadow: 0px 0px 16px #ffa500;
}

.white-preview div.btn-container i.fa-moon-o {
  color: #777;
}

p.by a {
  text-decoration: none;
  color: #000;
}

.dark-preview p.by a {
  color: #777;
}

.white-preview p.by a {
  color: #000;
}

.bnb-busd {
  @include Input();
  box-shadow: initial;
  margin-left: 4px;
  margin-right: 6px;
  width: 184px;
}